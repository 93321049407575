import './scss/main.scss'

import $ from 'jquery';

import 'slick-carousel'

import "bootstrap";

$(document).scroll(function() {
	if($(document).scrollTop() >= 90){
		$("nav.fixed").addClass("active");
	}else{
		$("nav.fixed").removeClass("active");
	}
});

$('form[id="formContat"]').submit(function(){
	$('.message').removeClass("success error")

	var nome = $('#nome').val();
	var email = $('#email').val();
	var telefone = $('#whatsapp').val();
	var mensagem = $('#mensagem').val();

	if(email==""){
		$('#email').addClass("required")
	}else{
		$('#email').removeClass("required")
	}
	if(nome==""){
		$('#nome').addClass("required")
	}else{
		$('#nome').removeClass("required")
	}
	if(telefone==""){
		$('#whatsapp').addClass("required")
	}else{
		$('#whatsapp').removeClass("required")
	}
	
	if(email=="" || nome=="" || telefone==""){
		$(".message").html("*Preencha todos os campos!")
		$('.message').addClass("error")
	}else{
		var data = {
			email: email, 
			nome: nome,
			telefone: telefone,
			site: 'Studio Light Assis'
		}
		$('form[id="formContat"] button').attr("disabled", true);
		$.ajax({
			type: "POST",
			url: "https://studiolight.suliincorporadora.com.br/envio/envio.php",
			data: data,
			beforeSend : function(){
				$(".message").html("Enviando..")
			}
		}).done(function(resultado){
			if(resultado==1){
				$('.message').addClass("success")
				$('form[id="formContat"]').trigger("reset");
				$(".message").html("Sua mensagem foi enviada com sucesso, em breve entraremos em contato.")
			}else{
				$(".message").html("Ocorreu um erro ao tentar enviar sua mensagem. Por favor tente novamente mais tarde!")
			}
			$('form[id="formContat"] button').attr("disabled", false);
		});
	}
	return false;
});

$('.slides-1').slick({
	slidesToShow: 1,
	centerPadding: '350px',
	slidesToScroll: 2,
	dots: true,
	centerMode: true,
	prevArrow: $('.slide1 .prev'),
	nextArrow: $('.slide1 .next'),
	responsive: [{
		breakpoint: 960,
			settings: {
				slidesToShow: 1,
				centerPadding: '0px',
				centerMode: false,
			}
		}]
});

/* $('.slides-1').on('beforeChange', function(event, slick, currentSlide, nextSlide){
	$('.slick-slide').removeClass("animeExit")
	$('.slick-slide[data-slick-index="'+currentSlide+'"]').addClass("animeExit");
}); */

$('.slides-2').slick({
	slidesToShow: 1,
	centerPadding: '350px',
	slidesToScroll: 2,
	dots: true,
	centerMode: true,
	prevArrow: $('.slide2 .prev'),
	nextArrow: $('.slide2 .next'),
	responsive: [{
		breakpoint: 960,
			settings: {
				slidesToShow: 1,
				centerPadding: '0',
			}
		}]
});

$('.slides-3').slick({
	slidesToShow: 2,
	slidesToScroll: 1,
	centerMode: false,
	prevArrow: $('.slide3 .prev'),
	nextArrow: $('.slide3 .next'),
	responsive: [{
		breakpoint: 1024,
		settings: {
			slidesToShow: 1
		}
		},{
		breakpoint: 600,
		settings: {
			slidesToShow: 1
		}
		},{
		breakpoint: 480,
		settings: {
			slidesToShow: 1
		}
	}]
});

$(document).on('click', 'a.row-scroll', function (event) {
    event.preventDefault();

    $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top
    }, 1000);
});

$(".button-menu").click(function(){
	$(".menu-mobile").addClass("active");
});

$(".button-menu-close").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(".menu-mobile li a").click(function(){
	$(".menu-mobile").removeClass("active");
});
